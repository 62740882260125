import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CallsService } from 'src/app/calls/calls.service';
import { User } from '../../models/user';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public user: User;
  public loginError = '';
  public status: boolean;
  public cargando: boolean;
  public isLogged: boolean;

  public dataFake: any;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private authService: AuthService,
    private callsService: CallsService
  ) {
    this.user = new User('', '');
    this.isLogged = false;
  }

  ngOnInit() {
    if (this.authService.getCurrentUser()) {
      this.isLogged = true;
    }
  }

  clearLoginErrorMessage() {
    this.loginError = null;
  }

  onSubmit(loginForm) {
    this.authService
      .loginUser(this.user.email.toLowerCase(), this.user.password)
      .subscribe(
        (response: any) => {
          console.log(response);

          this.isLogged = true;
          this.loginError = '';

          this.authService.setUser(response.userData);
          this.authService.setToken(response.token);
          this.authService.setLogs(response.calllog);
        },
        (error) => {
          console.log(error);
          this.loginError = 'Invalid user/password';
        }
      );
  }

  sendEmail(email) {}
}
