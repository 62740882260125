import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../../constants';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CallsService {
  _baseURL: string;
  public configCall: any = { gender: null, localLang: null, remoteLang: null };
  public localObj: any;
  public remoteObj: any;

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;
  }

  getLanguagesNew(): Observable<any> {
    const url = 'https://core.worldwidetechconnections.com/languages';
    return this.http.get(url, {}).pipe(map((data) => data));
  }

  setConfig(gender, localLang, remoteLang) {
    this.configCall.gender = gender;
    this.configCall.localLang = localLang;
    this.configCall.remoteLang = remoteLang;
  }

  setLocalObj(localObj) {
    this.localObj = localObj;
  }

  setRemoteObj(remoteObj) {
    this.remoteObj = remoteObj;
  }

  getLocalObj() {
    return this.localObj;
  }

  getRemoteObj() {
    return this.remoteObj;
  }
  getTokenTwilio() {
    return this.http.get('https://phlox-pointer-4752.twil.io/capability-token');
  }
  translatev2(audio, sourceLanguageCode, targetLanguageCode) {
    // console.log(this.localObj);
    // console.log(this.remoteObj);
    console.log('Local', sourceLanguageCode);
    console.log('Remoto', targetLanguageCode);

    let url = `https://core.worldwidetechconnections.com/services/sts/${sourceLanguageCode}/${targetLanguageCode}`;
    // let url = `https://core.worldwidetechconnections.com/services/sts/es/en`;
    let body = audio;
    return this.http.post(url, body).pipe(map((data) => data));
  }
}
