<div class="config-call">
  <!-- <div>
    <h3>Do you need a voice of a</h3>
    <div class="sex">
      <div (click)="selectSex('Male')" [ngClass]="{ active: voice === 'Male' }">
        <span>Male</span>
      </div>
      <div
        (click)="selectSex('Female')"
        [ngClass]="{ active: voice === 'Female' }"
      >
        <span>Female</span>
      </div>
    </div>
  </div> -->
  <div>
    <h3>Local Language</h3>
    <select
      name="languges"
      [(ngModel)]="localLanguage"
      class="custom-select"
      (change)="onChangeLocal($event)"
    >
      <option
        *ngFor="let language of languages; let i = index"
        value="{{ language.code }}"
      >
        {{ language.name }}
      </option>
    </select>
  </div>
  <div>
    <h3>Remote Language</h3>
    <select
      name="languges"
      [(ngModel)]="remoteLanguage"
      class="custom-select"
      (change)="onChangeRemote($event)"
    >
      <option
        *ngFor="let language of languages; let i = index"
        value="{{ language.code }}"
      >
        {{ language.name }}
      </option>
    </select>
  </div>
  <app-button
    *ngIf="!callConfigured"
    [text]="'START CLIENT'"
    [clase]="'primary'"
    (click)="startClient()"
  ></app-button>
  <div *ngIf="callConfigured">
    <div style="font-size: 20px">
      <span>
        <img src="./assets/img/svg/headset.svg" style="width: 2.5rem" alt="" />
        Transcript mode
      </span>
      <label class="switch">
        <input type="checkbox" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="botton-buttom">
      <app-button
        [text]="'Clean messages'"
        [clase]="'info'"
        (click)="clearMsg()"
      ></app-button>
      <app-button
        [text]="'Logout'"
        [clase]="'secondary'"
        (click)="logout()"
      ></app-button>
    </div>
  </div>
</div>
