import { CallsService } from './../../calls/calls.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-config-call',
  templateUrl: './config-call.component.html',
  styleUrls: ['./config-call.component.scss']
})
export class ConfigCallComponent implements OnInit {
  public languages: Array<any>;
  public localLanguage: string;
  public remoteLanguage: string;
  public voice: string;
  public callConfigured: boolean;

  @Output() clearLog = new EventEmitter();
  @Output() logOut = new EventEmitter();

  constructor(
    private callService: CallsService,
    private _router: Router,
    private authService: AuthService
  ) {
    this.localLanguage = 'english-united-states';
    this.remoteLanguage = 'english-united-states';
    this.voice = 'Male';
    this.callConfigured = false;
    this.languages = [];
  }
  ngOnInit(): void {
    if (this.callService.getLocalObj()) {
      console.log(this.callService.getLocalObj().code);
      this.localLanguage = this.callService.getLocalObj().code;
      this.remoteLanguage = this.callService.getRemoteObj().code;
    }
    this.callService.getLanguagesNew().subscribe(
      response => {
        for (let index = 0; index < response.length; index++) {
          let servicio = response[index].services;
          if (servicio.includes('tts') && servicio.includes('stt') && servicio.includes('ttt')) {
            this.languages.push(response[index]);
          }
        }

        let tempArray = this.languages.slice();
        this.languages = this.sortLanguages(tempArray);

      },
      error => {
        console.log(error);
      }
    );

    if (!this.callService.localObj) {
      this.callConfigured = false;
    } else {
      this.callConfigured = true;
    }
  }
  onChangeLocal(event) {
    this.callService.setLocalObj(this.getLangByCode(this.localLanguage));
  }
  onChangeRemote(event) {
    this.callService.setRemoteObj(this.getLangByCode(this.remoteLanguage));
  }

  startClient() {
    this.callService.setLocalObj(this.getLangByCode(this.localLanguage));
    this.callService.setRemoteObj(this.getLangByCode(this.remoteLanguage));
    this._router.navigate(['/calls']);
  }
  selectSex(sex) {
    this.voice = sex;
  }

  getLangByCode(code) {
    return this.languages.find(x => x.code === code);
  }

  clearMsg() {
    this.clearLog.emit([]);
  }
  logout() {
    this.logOut.emit(true);
    this.callService.localObj = null;
    this.callService.remoteObj = null;
    this.authService.logoutUser();
    this._router.navigate(['/']);
  }


  sortLanguages(data) {
    let aux = data.slice();
    aux.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    const spanishInternational = aux.find(lng => lng.code === "spanish-international");
    const englishUsa = aux.find(lng => lng.code === "english-united-states");
    const arraySorted = aux.filter(
      item => item.code !== "spanish - international" && item.code !== "english - united - states",
    );

    arraySorted.unshift(spanishInternational);
    arraySorted.unshift(englishUsa);
    return arraySorted;
  };
}
